<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(addUpdateItemAction)" @reset.prevent="resetForm" v-if="agentData != null">
        <b-row style="margin-bottom: 20px">
          <b-col cols="4">
            <h6 class="section-label text-primary float-left" style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              ">
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Agent
            </h6>
          </b-col>

          <b-col cols="8">
            <b-button v-if="$router.name == 'agent-view'" variant="outline-primary" class="btn-sm float-right" @click="
              $router.push({
                name: 'agent-view',
                params: { id: agentData.id + 1 },
              })
              ">
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button v-if="$router.name == 'agent-view'" variant="outline-primary" class="btn-sm float-right" @click="
              $router.push({
                name: 'agent-view',
                params: { id: agentData.id - 1 },
              })
              ">
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button variant="outline-primary" class="btn-sm float-right" @click="
              $router.push({
                name: 'agent-list',
              })
              ">
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste des Agents</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- Identity -->
        <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="UserIcon" size="18" />
              <span class="align-middle ml-50">Informations Personnelles</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="9">
                <b-row>
                  <b-col cols="12" md="12">
                    <!-- formCollaborator -->
                    <b-form-group>
                      <b-form-checkbox id="formCollaborator" v-model="agentData.type" name="formCollaborator">
                        Enregistrer comme collaborateur
                        <span class="text-warning">(Formulaire Simple)</span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6" md="4">
                    <validation-provider #default="validationContext" name="mattricule" rules="required">
                      <b-form-group label="Code/Mattricule" label-for="mattricule">
                        <b-form-input id="mattricule" v-model="agentData.mattricule"
                          :state="getValidationState(validationContext)" trim placeholder="Ex: MATTRICULE DFJ0001"
                          class="" />
                        <b-form-invalid-feedback>
                          Completer le code/matricule <span v-if="agentData.type!=true"> de l'agent</span>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="6" md="4">
                    <validation-provider #default="validationContext" name="firstname" rules="required">
                      <b-form-group label="Nom" label-for="firstname">
                        <b-form-input id="firstname" v-model="agentData.firstname"
                          :state="getValidationState(validationContext)" trim placeholder="Ex: Aimerie" class="" />
                        <b-form-invalid-feedback>
                          Completer le nom <span v-if="agentData.type!=true"> de l'agent</span>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="6" md="4">
                    <validation-provider #default="validationContext" name="lastname" rules="required">
                      <b-form-group label="Postnom" label-for="lastname">
                        <b-form-input id="lastname" v-model="agentData.lastname"
                          :state="getValidationState(validationContext)" trim placeholder="Ex: Lumumba" class="" />
                        <b-form-invalid-feedback>
                          Completer le Postnom <span v-if="agentData.type!=true"> de l'agent</span>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="6" md="4">
                    <validation-provider #default="validationContext" name="surname" rules="required">
                      <b-form-group label="Prenom" label-for="surname">
                        <b-form-input id="surname" v-model="agentData.surname"
                          :state="getValidationState(validationContext)" trim placeholder="Ex: Patrice" class="" />
                        <b-form-invalid-feedback>
                          Completer le prenom <span v-if="agentData.type!=true"> de l'agent</span>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="6" md="4">
                    <!-- gender -->
                    <validation-provider #default="validationContext" name="gender" rules="required">
                      <b-form-group label="Sexe" label-for="gender" :state="getValidationState(validationContext)">
                        <v-select v-model="agentData.gender" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="genderOptions" :reduce="(val) => val.value" :clearable="false" input-id="gender" />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          Selectionner le Sexe
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="6" md="4" v-if="agentData.type!=true">
                    <!-- gender -->
                    <validation-provider #default="validationContext" name="marital_status" rules="required">
                      <b-form-group label="Etat Civile" label-for="marital_status"
                        :state="getValidationState(validationContext)">
                        <v-select v-model="agentData.marital_status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="maritalOptions" :reduce="(val) => val.value" :clearable="false"
                          input-id="marital_status" />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          Définissez l'Etat Civile
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="6" md="4" v-if="agentData.type!=true">
                    <validation-provider #default="validationContext" name="nationality" rules="required">
                      <b-form-group label="Nationalité" label-for="nationality">
                        <b-form-input id="nationality" v-model="agentData.nationality"
                          :state="getValidationState(validationContext)" trim placeholder="Ex: Congolaise" class="" />
                        <b-form-invalid-feedback>
                          Completer la nationalité
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="6" md="4" v-if="agentData.type!=true">
                    <validation-provider #default="validationContext" name="birthplace" rules="required">
                      <b-form-group label="Lieu de naissance" label-for="birthplace">
                        <b-form-input id="birthplace" v-model="agentData.birthplace"
                          :state="getValidationState(validationContext)" trim placeholder="Ex: Goma" class="" />
                        <b-form-invalid-feedback>
                          Completer lieu de naissance
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="6" md="4" v-if="agentData.type!=true">
                    <validation-provider #default="validationContext" name="birthdate" rules="required">
                      <b-form-group label="Date de Naissance" label-for="birthdate">
                        <b-form-datepicker id="birthdate" v-model="agentData.birthdate"
                          :state="getValidationState(validationContext)" trim placeholder="Ex: 30 Juin 1960"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          class="mb-1" />
                        <b-form-invalid-feedback>
                          Completer Date de naissance
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="3">
                <b-col cols="6" md="6">
                  <b-form-group label="Photo" label-for="picture">
                    <!-- media -->
                    <b-media no-body>
                      <b-media-body class="mt-75 ml-75">
                        <!-- upload button -->
                        <b-form-file ref="refInputEl" v-model="imageFile" accept=".jpg, .png, .gif" plain hidden
                          @input="onFileChange" />
                      </b-media-body>
                    </b-media>
                    <!--/ media -->

                    <!-- Media -->
                    <b-media class="mb-2">
                      <template #aside>
                        <div>
                          <b-avatar ref="previewEl" :src="server_url + agentData.picture"
                            :text="avatarText(agentData.firstname)" @input="inputImageRenderer" size="113px" rounded />
                          <br />
                          <b-button v-if="$router.currentRoute.firstname != 'item-view'" variant="primary" size="sm"
                            class="mb-75 mr-75" style="padding: 10px; margin-top: 10px"
                            @click="$refs.refInputEl.$el.click()">
                            <feather-icon icon="CameraIcon" size="18" />
                            {{ imageActionText }}
                          </b-button>
                        </div>
                      </template>
                    </b-media>
                  </b-form-group>
                </b-col>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <!-- Citoyenneté -->
        <b-card no-body class="border mt-1" v-if="agentData.type!=true">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="UserIcon" size="18" />
              <span class="align-middle ml-50">Famille</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row class="">
              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="conjunct" rules="required">
                  <b-form-group label="Nom du Conjoit(e)" label-for="conjunct">
                    <b-form-input id="conjunct" v-model="agentData.conjunct"
                      :state="getValidationState(validationContext)" trim placeholder="Ex: Jean(e) de Kama" class="" />
                    <b-form-invalid-feedback>
                      Completer le Nom du Conjoit(e)
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="childs" rules="required">
                  <b-form-group label="Enfant(s) a charge" label-for="childs">
                    <b-form-input id="childs" v-model="agentData.childs" :state="getValidationState(validationContext)"
                      trim placeholder="Ex: 4" class="" />
                    <b-form-invalid-feedback>
                      Completer Total Enfant(s) a charge
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="father_name" rules="required">
                  <b-form-group label="Nom du père" label-for="father_name">
                    <b-form-input id="father_name" v-model="agentData.father_name"
                      :state="getValidationState(validationContext)" trim placeholder="Ex: Patrice Emery Lumumba"
                      class="" />
                    <b-form-invalid-feedback>
                      Completer le nom du père
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="mother_name" rules="required">
                  <b-form-group label="Nom de la mère" label-for="mother_name">
                    <b-form-input id="mother_name" v-model="agentData.mother_name"
                      :state="getValidationState(validationContext)" trim placeholder="Ex: Pauline Opango" class="" />
                    <b-form-invalid-feedback>
                      Completer le nom de la mère
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <!-- Citoyenneté -->
        <b-card no-body class="border mt-1" v-if="agentData.type!=true">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="ShieldIcon" size="18" />
              <span class="align-middle ml-50">Citoyenneté</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row class="">
              <b-col cols="6" md="3">
                <!-- state -->
                <validation-provider #default="validationContext" name="state" rules="required">
                  <b-form-group label="Province d'Origine" label-for="state"
                    :state="getValidationState(validationContext)">
                    <b-form-input id="state" v-model="agentData.state" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :state="getValidationState(validationContext)" trim placeholder="Ex: Nord Kivu" class="" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Province d'origine
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="territory" rules="required">
                  <b-form-group label="Territoire d'origine" label-for="territory">
                    <b-form-input id="territory" v-model="agentData.territory"
                      :state="getValidationState(validationContext)" trim placeholder="Ex: Lubero" class="" />
                    <b-form-invalid-feedback>
                      Completer le Territoire d'origine
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="community" rules="required">
                  <b-form-group label="Collectivité d'origine" label-for="community">
                    <b-form-input id="community" v-model="agentData.community"
                      :state="getValidationState(validationContext)" trim placeholder="Ex: Buhimba" class="" />
                    <b-form-invalid-feedback>
                      Completer la collectivité d'origine
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="3">
                <b-form-group label="Ethnie" label-for="ethnicity">
                  <b-form-input id="ethnicity" v-model="agentData.ethnicity" trim placeholder="Ex: Nande" class="" />
                </b-form-group>
              </b-col>

              <b-col cols="6" md="3">
                <!-- immigration_status -->
                <validation-provider #default="validationContext" name="immigration_status" rules="required">
                  <b-form-group label="Statut d'immigration" label-for="immigration_status"
                    :state="getValidationState(validationContext)">
                    <v-select v-model="agentData.immigration_status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="immigrationStatusOptions" :reduce="(val) => val.value" :clearable="false"
                      input-id="immigration_status" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Statut d'immigration
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="3">
                <b-form-group label="N° National" label-for="national_number">
                  <b-form-input id="national_number" v-model="agentData.national_number" trim
                    placeholder="Ex: 011225878963" class="" />
                </b-form-group>
              </b-col>

              <b-col cols="6" md="3">
                <b-form-group label="N° Passport" label-for="passport_number">
                  <b-form-input id="passport_number" v-model="agentData.passport_number" trim placeholder="Ex: 0B55966DQ"
                    class="" />
                </b-form-group>
              </b-col>

              <b-col cols="6" md="3">
                <b-form-group label="N° Permis de Conduire" label-for="driver_licence">
                  <b-form-input id="driver_licence" v-model="agentData.driver_licence" trim
                    placeholder="Ex: PP586600215NK" class="" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <!-- Dossier academique -->
        <b-card no-body class="border mt-1" v-if="agentData.type!=true">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="FolderIcon" size="18" />
              <span class="align-middle ml-50">Dossier Scolaire / Academique</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row class="">
              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="academic_institution" rules="required">
                  <b-form-group label="Dernier Etablissement frequenté " label-for="academic_institution">
                    <b-form-input id="academic_institution" v-model="agentData.academic_institution"
                      :state="getValidationState(validationContext)" trim placeholder="Ex: Université de Goma" class="" />
                    <b-form-invalid-feedback>
                      Completer Dernier Etablissement Scolaire/Academique
                      frequenté
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- meilleur titre obteni -->
              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="academic_level" rules="required">
                  <b-form-group label="Titre Scolaire/Academique élévé" label-for="academic_level">
                    <b-form-input id="academic_level" v-model="agentData.academic_level"
                      :state="getValidationState(validationContext)" trim placeholder="Ex: Licence en Economie"
                      class="" />
                    <b-form-invalid-feedback>
                      Completer Titre Scolaire/Academique le plus élévé
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="academic_department" rules="required">
                  <b-form-group label="Faculté" label-for="academic_department">
                    <b-form-input id="academic_department" v-model="agentData.academic_department"
                      :state="getValidationState(validationContext)" trim placeholder="Ex: Economie" class="" />
                    <b-form-invalid-feedback>
                      Completer Faculté/Departement
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="career" rules="required">
                  <b-form-group label="Option/Carrière" label-for="career">
                    <b-form-input id="career" v-model="agentData.career" :state="getValidationState(validationContext)"
                      trim placeholder="Ex: Gestion Financière" class="" />
                    <b-form-invalid-feedback>
                      Completer Option/Carrière
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <!-- Dossier de travail -->
        <b-card no-body class="border mt-1" v-if="agentData.type!=true">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="FolderIcon" size="18" />
              <span class="align-middle ml-50">Dossier actuel de travail</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row class="">
              <!-- contract -->
              <b-col cols="6" md="3">
                <!-- contract -->
                <validation-provider #default="validationContext" name="contract" rules="required">
                  <b-form-group label="Nature du Contrat" label-for="contract"
                    :state="getValidationState(validationContext)">
                    <v-select v-model="agentData.contract" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="contractOptions" :reduce="(val) => val.value" :clearable="false" input-id="contract" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Statut d'emplois
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- department -->
              <b-col cols="6" md="3">
                <!-- department -->
                <validation-provider #default="validationContext" name="department" rules="required">
                  <b-form-group label="Sélection du Département" label-for="department"
                    :state="getValidationState(validationContext)">
                    <v-select v-model="agentData.department" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="departmentOptions" :reduce="(val) => val.value" :clearable="false"
                      input-id="department" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Sélection du Département
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- job_description -->
              <b-col cols="6" md="3">
                <b-form-group label="Description d'emploi" label-for="job_description">
                  <b-form-input :id="'job_description'" v-model="agentData.job_description" trim
                    placeholder="Ex: Chargé de Suivie et Evaluation" />
                </b-form-group>
              </b-col>

              <!-- pay_level -->
              <!-- <b-col cols="6" md="3">
                <validation-provider
                  #default="validationContext"
                  name="pay_level"
                  rules="required"
                >
                  <b-form-group
                    label="Niveau de Payement"
                    label-for="pay_level"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="agentData.pay_level"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="payLevelOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="pay_level"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Sélection du Niveau de Payement
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col> -->

              <!--supervisor -->
              <b-col cols="6" md="3">
                <b-form-group label="Superviseur" label-for="supervisor">
                  <v-select v-model="agentData.supervisor" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="agentOptions" :reduce="(val) => val.value" :clearable="false" input-id="supervisor" />
                </b-form-group>
              </b-col>

              <!-- supervisor_indirect -->
              <!-- <b-col cols="6" md="3">
                <b-form-group
                  label="Superviseur Indirect"
                  label-for="supervisor_indirect"
                >
                  <v-select
                    v-model="agentData.supervisor_indirect"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="agentOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="supervisor_indirect"
                  />
                </b-form-group>
              </b-col> -->

              <!-- approver -->
              <!-- <b-col cols="6" md="3">
                <validation-provider
                  #default="validationContext"
                  name="approver"
                  rules="required"
                >
                  <b-form-group
                    label="Aprobateur"
                    label-for="approver"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="agentData.approver"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="agentOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="approver"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Sélection du Superviseur
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col> -->

              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="date_signup" rules="required">
                  <b-form-group label="Date d'Engagement" label-for="date_signup">
                    <b-form-datepicker id="date_signup" v-model="agentData.date_signup"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      :state="getValidationState(validationContext)" trim class="mb-1" />
                    <b-form-invalid-feedback>
                      Completer Date d'Engagement
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- <b-col cols="6" md="3">
                <validation-provider
                  #default="validationContext"
                  name="date_confirmation"
                  rules="required"
                >
                  <b-form-group
                    label="Date de Confirmation"
                    label-for="date_confirmation"
                  >
                    <b-form-datepicker
                      id="date_confirmation"
                      v-model="agentData.date_confirmation"
                      :state="getValidationState(validationContext)"
                      trim
                      class="mb-1"
                    />
                    <b-form-invalid-feedback>
                      Completer Date de Confirmation
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="3">
                <validation-provider
                  #default="validationContext"
                  name="date_termination"
                  rules="required"
                >
                  <b-form-group
                    label="Date de Fin Contrat"
                    label-for="date_termination"
                  >
                    <b-form-datepicker
                      id="date_termination"
                      v-model="agentData.date_termination"
                      :state="getValidationState(validationContext)"
                      trim
                      class="mb-1"
                    />
                    <b-form-invalid-feedback>
                      Completer Date de Fin Contrat
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col> -->
            </b-row>
          </b-card-body>
        </b-card>

        <!-- Dossier de travail -->
        <b-card no-body class="border mt-1" v-if="agentData.type!=true">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="FolderIcon" size="18" />
              <span class="align-middle ml-50">Employeur(s) Précédent(s)</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row class="pb-2" v-for="(module, e_index) in agentData.employers" :key="e_index" ref="row">
              <b-col cols="12" md="3">
                <validation-provider #default="validationContext" :name="'name.employer.' + e_index" rules="required">
                  <b-form-group :label-for="'name.employer.' + e_index">
                    <label><span class="text-primary"> Nom Employeur </span>
                      {{ e_index + 1 }}
                    </label>
                    <b-form-input :id="'name.employer.' + e_index" v-model="agentData.employers[e_index].name"
                      :state="getValidationState(validationContext)" trim placeholder="Ex: Google LLC" />
                    <b-form-invalid-feedback>
                      Completer d'employeur {{ e_index + 1 }} s'il vous plait
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="3">
                <b-form-group label="Description" label-for="description">
                  <b-form-input :id="'description.employer.' + e_index" v-model="agentData.employers[e_index].description"
                    trim placeholder="Ex: Description du poste, Charges, Responsabilités..." class="" />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3">
                <b-form-group label="Dernier Employeur" label-for="last_employer">
                  <b-form-checkbox :id="'last.employer.' + e_index" v-model="agentData.employers[e_index].last_employer"
                    @check="
                      lastEmployerValidation(
                        agentData.employers[e_index].last_employer
                      )
                      " />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3">
                <b-form-group>
                  <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2"
                    @click="removeEmployer(e_index)">
                    <feather-icon icon="XIcon" class="mr-10" />
                    <span>Supprimer</span>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row style="margin-top: 5px">
              <b-col cols="12" md="4">
                <b-button variant="outline-primary" class="btn-sm float-left" @click="addNewStepForm">
                  <feather-icon icon="PlusIcon" size="12" />
                  <span class="xl-none">Ajouter un Employeur</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="FolderIcon" size="18" />
              <span class="align-middle ml-50">Contacts</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row class="">
              <!-- adress phisique -->
              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="adress" rules="required">
                  <b-form-group label="Adresse" label-for="adress">
                    <b-form-input id="adress" v-model="agentData.adress" :state="getValidationState(validationContext)"
                      trim placeholder="Ex: Q. Les Volcans, Commune de Goma, Avenue du Golf n°002" class="" />
                    <b-form-invalid-feedback>
                      Completer Adresse
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- country -->
              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="country" rules="required">
                  <b-form-group label="Pays" label-for="country">
                    <b-form-input id="country" v-model="agentData.country" :state="getValidationState(validationContext)"
                      trim placeholder="Ex: République Démocratique du Congo" class="" />
                    <b-form-invalid-feedback>
                      Completer Pays
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- state -->
              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="state" rules="required">
                  <b-form-group label="Province" label-for="state">
                    <b-form-input id="state" v-model="agentData.state" :state="getValidationState(validationContext)" trim
                      placeholder="Ex: Nord Kivu" class="" />
                    <b-form-invalid-feedback>
                      Completer Province
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- town -->
              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="town" rules="required">
                  <b-form-group label="Ville" label-for="town">
                    <b-form-input id="town" v-model="agentData.town" :state="getValidationState(validationContext)" trim
                      placeholder="Ex: Goma" class="" />
                    <b-form-invalid-feedback>
                      Completer Ville
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- phone -->
              <b-col cols="6" md="3">
                <validation-provider #default="validationContext" name="phone" rules="required">
                  <b-form-group label="N° Téléphone" label-for="phone">
                    <b-form-input id="phone" v-model="agentData.phone" :state="getValidationState(validationContext)" trim
                      placeholder="Ex: +243 990 00 00 00" class="" />
                    <b-form-invalid-feedback>
                      Completer N° Téléphone
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- phone 2 -->
              <b-col cols="6" md="3" v-if="agentData.type!=true">
                <b-form-group label="N° de Téléphone Secondaire" label-for="phone_2">
                  <b-form-input id="phone_2" v-model="agentData.phone_2" trim placeholder="Ex: +243 990 00 00 00"
                    class="" />
                </b-form-group>
              </b-col>

              <!-- phone travail -->
              <b-col cols="6" md="3" v-if="agentData.type!=true">
                <b-form-group label="N° Téléphone de Travail" label-for="phone_work">
                  <b-form-input id="phone_work" v-model="agentData.phone_work" trim placeholder="Ex: +243 990 00 00 00"
                    class="" />
                </b-form-group>
              </b-col>

              <!-- email privé -->
              <b-col cols="6" md="3" v-if="agentData.type!=true">
                <b-form-group label="Email Privé" label-for="email_private">
                  <b-form-input id="email_private" v-model="agentData.email_private" trim
                    placeholder="Ex: agent@gmail.com" class="" />
                </b-form-group>
              </b-col>

              <!-- email travail -->
              <b-col cols="6" md="3" v-if="agentData.type!=true">
                <b-form-group label="Email Professionnelle" label-for="email_work">
                  <b-form-input id="email_work" v-model="agentData.email_work" trim placeholder="Ex: agent@dfj.org"
                    class="" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-card no-body class="border mt-1" v-if="agentData.type!=true">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="ShieldIcon" size="18" />
              <span class="align-middle ml-50">Autres Informations</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row class="">
              <b-col cols="6" md="3">
                <b-form-group label="Personne contact pour les urgences" label-for="urgent_person">
                  <b-form-input id="urgent_person" v-model="agentData.urgent_person" trim placeholder="Ex: Jesus Christ"
                    class="" />
                </b-form-group>
              </b-col>

              <b-col cols="6" md="3">
                <b-form-group label="Beneficiaire en cas de decès" label-for="beneficiary_if_death">
                  <b-form-input id="beneficiary_if_death" v-model="agentData.beneficiary_if_death" trim
                    placeholder="Ex: Kimpa Vita Nsimba Foundation" class="" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button ref="refSubmitEl" type="submit" variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'">
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>
            <b-button variant="outline-secondary" type="reset" @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'">
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="agentData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import {
  BMedia,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BAvatar,
  BFormFile,
  BMediaAside,
  BTable,
  BCardHeader,
  BCardTitle,
  // Form Validation
  BFormInvalidFeedback,
  BFormDatepicker,
  BMediaBody,
  BImg,
} from "bootstrap-vue";

// sweet alert
import { codeBasic } from "../../extensions/sweet-alert/code";
// alerts
import SweetAlertBasic from "../../extensions/sweet-alert/SweetAlertBasic.vue";
import SweetAlertPosition from "../../extensions/sweet-alert/SweetAlertPosition.vue";
import SweetAlertAnimation from "../../extensions/sweet-alert/SweetAlertAnimation.vue";
import SweetAlertTypes from "../../extensions/sweet-alert/SweetAlertTypes.vue";
import SweetAlertOption from "../../extensions/sweet-alert/SweetAlertOption.vue";
import SweetAlertConfirmOption from "../../extensions/sweet-alert/SweetAlertConfirmOption.vue";
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { codeIcon } from "../../extensions/toastification/code";
import ToastificationVariant from "../../extensions/toastification/ToastificationVariant.vue";
import ToastificationPosition from "../../extensions/toastification/ToastificationPosition.vue";
import ToastificationIcon from "../../extensions/toastification/ToastificationIcon.vue";
import ToastificationTimeout from "../../extensions/toastification/ToastificationTimeout.vue";

import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "agent";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    BFormDatepicker,
    Logo,
    BMedia,
    BMediaBody,
    BFormFile,
    BMediaAside,
    BImg,
    BAvatar,
    BTable,
    BCardHeader,
    BCardTitle,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    // alerts
    SweetAlertBasic,
    SweetAlertPosition,
    SweetAlertAnimation,
    SweetAlertTypes,
    SweetAlertOption,
    SweetAlertConfirmOption,
    // notification
    ToastificationVariant,
    ToastificationPosition,
    ToastificationIcon,
    ToastificationTimeout,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      imageActionText: "",
      imageFile: null,
      actionIndex: 0,
      sku_agent: "",
      image_url: "",
      image_changed: false,
      showed: true,
      loading: false,
      server_url: $themeConfig.app.remote_server_url,
      lastEmployerSelected: false,
      branchOptions: [],
      agentOptions: [],
      departmentOptions: [],
    };
  },
  props: {
    agentData: {
      type: Object,
    },
    defaultagentData: {
      type: Object,
    },
    employeeformblankData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  async mounted() {
    // console
    if (router.currentRoute.name == "agent-add-new") {
      var now = new Date();
      this.sku_agent = "ag-" + now.getTime();

      this.operationTitle = "Nouvel ";
      this.imageActionText = "Ajouter ";
      this.operationIcon = "AddIcon";
    }
    if (router.currentRoute.name == "agent-view") {
      this.sku_agent = router.currentRoute.params.id;
      this.operationTitle = "Détails de l'";
      this.imageActionText = "?";
      this.operationIcon = "EyeIcon";
    }
    if (router.currentRoute.name == "agent-edit") {
      this.sku_agent = router.currentRoute.params.id;
      this.operationTitle = "Modification de l'";
      this.operationIcon = "EditIcon";
      this.imageActionText = "Modifier";
    }

    if (router.currentRoute.name == moduleName + "-add-new") {
      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }
    if (router.currentRoute.name == moduleName + "-edit") {
      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier";
      this.actionIndex = 3;
      this.showed = true;
    }

    // load agents list
    await myApi
      .get(`agent`)
      .then((response) => {
        var data = response.data.data;
        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.agentOptions.push({
            label:
              data[i].firstname +
              " " +
              data[i].lastname +
              " " +
              data[i].surname,
            value: data[i].sku_agent,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });

    // branchOptions
    await myApi
      .get(`branch`)
      .then((response) => {
        var data = response.data.data;
        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.branchOptions.push({
            label: data[i].name,
            value: data[i].sku_branch,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });

    // offline get
    const userData = JSON.parse(localStorage.getItem("userData"));
    var departmentData = userData.departmentData;

    for (let index = 0; index < departmentData.length; index++) {
      const department = departmentData[index];
      if (department.route != "dashboard") {
        this.departmentOptions.push({
          label: department.name,
          value: department.name,
        });
      }
    }
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    async onFileChange(e) {
      const file = this.imageFile;

      let formData = new FormData();
      formData.append("picture", file);
      formData.append("sku_agent", this.sku_agent);

      var defaultActionText = this.imageActionText;
      this.imageActionText = "Envoie Photo...";

      await myApi
        .post("upload_agent_image", formData, {
          header: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.status == 200) {
            // preview image (refresh)
            this.image_url = response.data.full_url;

            this.agentData.picture = response.data.picture;

            this.image_changed = true;

            this.imageActionText = defaultActionText;

            // preview
            this.showToast(
              "Envoie Photo",
              response.data.message,
              "success",
              "CheckIcon",
              "top-center",
              2000
            );

            // local session update
          } else {
            this.imageActionText = defaultActionText;
            this.errors.push(response.message);
            this.showToast(
              "Erreur Survenu",
              response.message,
              "success",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          this.imageActionText = defaultActionText;
          //
          this.alertAutoClose(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            5000,
            "btn btn-warning"
          );
        });
    },
    lastEmployerValidation(checkBox) {
      this.lastEmployerSelected = true;
    },
    addNewStepForm() {
      this.$props.agentData.employers.push(
        JSON.parse(JSON.stringify(this.$props.employeeformblankData))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => { }, 350);
      });
    },
    addNewDepartmentInEmployerForm(module_index) {
      this.$props.agentData.employers[module_index].items.push(
        JSON.parse(JSON.stringify(this.$props.itemformblankData))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          // this.$refs.form[0].style.overflow = null;
        }, 350);
      });
    },
    updateEmployerForm(e_index, index, val) {
      const { cost, qty, buy_unit, description } = val;
      // ---------------------------------------------------------
      this.$props.agentData.employers[e_index].items[index].cost = cost;
      this.$props.agentData.employers[e_index].items[index].qty_unit = buy_unit;
      if (
        this.$props.agentData.employers[e_index].items[index].qty == null ||
        this.$props.agentData.employers[e_index].items[index].qty == undefined
      ) {
        // default qty
        this.$props.agentData.employers[e_index].items[index].qty = 1;
      }
      this.$props.agentData.employers[e_index].items[index].description =
        description;
    },
    removeEmployer(e_index) {
      this.$props.agentData.employers.splice(e_index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form[0].scrollHeight);
      });
    },
    reinitForm() {
      this.$props.agentData = this.defaultagentData;

      if (router.currentRoute.name == "agent-add-new") {
        // init empty agent data
        // agentData = this.defaultagentData;
      } else {
        // requirement //
        // store
        //   .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
        //     id: router.currentRoute.params.id,
        //   })
        //   .then((response) => {
        //     agentData.value = response.data;
        //   })
        //   .catch((error) => {
        //     if (error.response.status === 404) {
        //       agentData.value = undefined;
        //     }
        //   });
      }
      //

      // remove all occurences
      // initialise 1 element
      // this.$props.agentData.steps.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    },
    formReadyForSubmission() {
      // validation check condition
      this.errors = [];
      if (this.$props.agentData.name == "") {
        this.errors.push("Compléter le nom du agent svp.");
      }
      if (this.errors.length > 0) {
        // console.log(this.errors);
        return false;
      } else {
        return true;
      }
    },
    async addUpdateItemAction() {

      // ---------------------------
      let formData = new FormData();
      // ---------------------------
      const defaultActionText = this.actionName;

      let method = "";
      let params = "";

      if (router.currentRoute.name == "agent-add-new") {
        this.actionName = "Enregistrement...";
        method = "post";
      }
      if (router.currentRoute.name == "agent-view") {
        method = "get";
      }
      if (router.currentRoute.name == "agent-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.agentData.id;
      }

      // finalise form data
      formData = this.$props.agentData;

      myApi({
        url: "agent" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;

          if (response.status == 200 || response.status == 201) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "agent-list" });
              }
            });
          } else {
            this.actionName = defaultActionText; 
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          this.actionName = defaultActionText;
          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "AlertTriangleIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "agent-view" });
    },
    goToNextItem() {
      router.push({ name: "agent-list" });
    },
    goToList() {
      router.push({ name: "agent-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-agent";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetagentData = () => {
      // this.agentData = this.$props.agentData;
    };

    let { refFormObserver, getValidationState, resetForm } =
      formValidation(resetagentData);
    //
    // let itemsList = ref([]);
    // store.dispatch("app-agent/fetchItems").then((response) => {
    //   itemsList.value = response.data;
    //   // itemsList = response.data;
    // });

    // fill items
    // let itemsOptions = itemsList;

    const genderOptions = [
      { label: "Homme", value: "M" },
      { label: "Femme", value: "F" },
      { label: "Autre", value: "-" },
    ];
    const maritalOptions = [
      { label: "Marié(e)", value: "Marié" },
      { label: "Célibaire", value: "Célibaire" },
      { label: "Séparé(e)", value: "Séparé(e)" },
      { label: "Divorcé(e)", value: "Divorcé(e)" },
      { label: "Veuf(ve)", value: "Veuf(ve)" },
      { label: "Autre", value: "-" },
    ];

    const immigrationStatusOptions = [
      { label: "Citoyen(e)", value: "citizen" },
      { label: "Résident(e) Permanent(e)", value: "permanent_resident" },
      { label: "Titulaire d'un Laisser Passer", value: "holder_of_a_pass" },
      {
        label: "Titulaire d'un Permis de Travail",
        value: "holder_of_a_work_permit",
      },
    ];

    const contractOptions = [
      { label: "CDI | Contrat à Durée Indeterminée", value: "CDI" },
      { label: "CDD | Contrat à Durée Determinée", value: "CDD" },
      { label: "Autre Contrat", value: "-" },
    ];

    const careerOptions = [
      { label: "Chef de projet", value: "-" },
      { label: "Ingénieur logiciel", value: "" },
      { label: "Ingénieur logiciel adjoint", value: "" },
      { label: "Ingénieur AQ", value: "" },
      { label: "Chef de produit", value: "" },
      { label: "Ingénieur QA adjoint", value: "" },
      { label: "Chef de projet technique", value: "" },
      { label: "Responsable avant-vente", value: "" },
    ];

    const payLevelOptions = [
      { label: "Gestionnaire", value: "manager" },
      { label: "Administrateur", value: "admin" },
      { label: "Exécutif", value: "executif" },
      { label: "Assistant", value: "assistant" },
    ];

    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.agentData.avatar = base64;
      }
    );

    return {
      // itemsList,
      // itemsOptions,
      refFormObserver,
      getValidationState,
      resetForm,
      immigrationStatusOptions,
      maritalOptions,
      genderOptions,
      refInputEl,
      previewEl,
      contractOptions,
      careerOptions,
      payLevelOptions,
      inputImageRenderer,
      avatarText,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
